import { Container, Heading, Link, Text } from "@chakra-ui/react";

export default function () {
  return (
    <Container maxW="container.lg" mt="8vh" textAlign="left">
      <Heading size="4xl">403</Heading>
      <br />
      <Text fontSize="xl">Sorry, but you aren't allowed to access that.</Text>
      <br />
      <Link color="#646cff" onClick={() => history.go(-1)}>
        Go back
      </Link>
    </Container>
  );
}
