import { Button, Card, Container, Heading, VStack } from "@chakra-ui/react";

export default function () {
  return (
    <Container pt="16vh">
      <Card borderRadius="32px" p="4vh">
        <VStack alignContent="center" gap="2vh">
          <Heading>Log in to Car Crushers</Heading>
          <br />
          <Button
            as="a"
            borderRadius="24px"
            colorScheme="blue"
            href="/api/auth/oauth"
          >
            Log in with Discord
          </Button>
        </VStack>
      </Card>
    </Container>
  );
}
